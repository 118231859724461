<template>
	<div class="page">
		<van-nav-bar title="开票说明" left-arrow border fixed placeholder safe-area-inset-top @click-left="onClickLeft" />
		<div class="technological bg-white border-top">
			<div class="flex prl" style="align-items: center;">
				<div class="technological-box"></div>
				<div class="technological-txt">开票流程</div>
			</div>
			<div class="technological-gray prl">电子发票：</div>
			<div class="flex" style="justify-content: space-around;">
				<div>
					<div class="technological-img">
						<van-image width="0.88rem" height="0.88rem" fit="cover"
							:src="require('../../../img/invoice/dian_1.png')" />
					</div>
					<div class="technological-text">申请服务费后<br>开具发票</div>
				</div>
				<div>
					<div class="technological-img">
						<van-image width="0.88rem" height="0.88rem" fit="cover"
							:src="require('../../../img/invoice/dian_2.png')" />
					</div>
					<div class="technological-text">服务费审核通<br>过后上传发票</div>
				</div>
				<div>
					<div class="technological-img">
						<van-image width="0.88rem" height="0.88rem" fit="cover"
							:src="require('../../../img/invoice/dian_3.png')" />
					</div>
					<div class="technological-text">审核通过后发<br>放服务费</div>
				</div>
			</div>
			<div class="technological-gray prl">纸质发票：</div>
			<div class="flex" style="justify-content: space-around;">
				<div>
					<div class="technological-img">
						<van-image width="0.88rem" height="0.88rem" fit="cover"
							:src="require('../../../img/invoice/zhi_1.png')" />
					</div>
					<div class="technological-text">申请服务费后<br>开具发票</div>
				</div>
				<div>
					<div class="technological-img">
						<van-image width="0.88rem" height="0.88rem" fit="cover"
							:src="require('../../../img/invoice/zhi_2.png')" />
					</div>
					<div class="technological-text">审核通过后上<br>传并邮寄发票</div>
				</div>
				<div>
					<div class="technological-img">
						<van-image width="0.88rem" height="0.88rem" fit="cover"
							:src="require('../../../img/invoice/zhi_4.png')" />
					</div>
					<div class="technological-text">平台收到发票<br>审核通过后发<br>放服务费</div>
				</div>
			</div>
			<div class="flex prl" style="align-items: center;">
				<div class="technological-box"></div>
				<div class="technological-txt">发票主体</div>
			</div>
			<div class="technological-main prl">开具发票的主体需与您账户认证信息一致，公司将按照您账户认证的主体进行发票的审核与打款</div>
			<div class="flex prl mt" style="align-items: center;">
				<div class="technological-box"></div>
				<div class="technological-txt">开票内容</div>
			</div>
			<div class="technological-main prl">服务费，其他内容的发票公司一律不予接收</div>
			<div class="flex prl mt" style="align-items: center;">
				<div class="technological-box"></div>
				<div class="technological-txt">开票金额</div>
			</div>
			<div class="technological-main prl">发票金额需与提交申请的服务费金额一致</div>
		</div>
		<div class="information bg-white">
			<div class="information-header">开票信息</div>
			<div class="bg-white">
				<div class="flex mt" style="align-items: center;">
					<div class="information-left">名称：</div>
					<div class="text-df">天津恒翊科技有限公司</div>
				</div>
				<div class="flex" style="align-items: center;">
					<div class="information-left">纳税识别号：</div>
					<div class="text-df">91120223MA06FELG7T</div>
				</div>
				<div class="flex">
					<div class="information-left">地址：</div>
					<div class="text-df">天津市静海区静海镇汇海<br>道香格里拉50-3-102</div>
				</div>
				<div class="flex" style="align-items: center;">
					<div class="information-left">电话：</div>
					<div class="text-df">16602281817</div>
				</div>
				<div class="flex" style="align-items: center;">
					<div class="information-left">开户行：</div>
					<div class="text-df">天津银行静海支行</div>
				</div>
				<div class="flex" style="align-items: center;">
					<div class="information-left">账号：</div>
					<div class="text-df">155801201080049848</div>
				</div>
				<div class="information-btn" v-clipboard:copy="copys" v-clipboard:success="copy">复制开票信息</div>
			</div>
		</div>

		<div class="technological"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				copys: '名称：天津恒翊科技有限公司\n纳税识别号：91120223MA06FELG7T\n地址：天津市静海区静海镇汇海道香格里拉50-3-102\n电话：16602281817\n开户行：天津银行静海支行\n账号：155801201080049848'
			}
		},
		methods: {
			//返回
			onClickLeft() {
				if (window.history.length <= 1) {
					this.$router.push({
						path: '/'
					})
					return false
				} else {
					this.$router.go(-1)
				}
			},
			copy() {
				this.$toast("复制成功");
			}
		},
		created(){
			this.openid = this.$store.state.openid
		}
	}
</script>

<style scoped="scoped">
	.information-btn {
		width: 5.76rem;
		height: 0.84rem;
		border-radius: 0.08rem;
		border: 0.02rem solid #5C5C5C;
		color: #5C5C5C;
		line-height: 0.84rem;
		text-align: center;
		font-size: 0.3rem;
		margin: 0 auto;
		margin-top: 0.5rem;
	}

	.text-df {
		font-size: 0.28rem;
	}

	.information-left {
		font-size: 0.3rem;
		width: 2.88rem;
		color: #AEADAD;
		text-indent: 0.88rem;
		height: 0.6rem;
		line-height: 0.6rem;
	}

	.information-header {
		width: 6.86rem;
		height: 1.08rem;
		line-height: 1.08rem;
		background-color: #FFD4C5;
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;
		text-align: center;
		color: #F15223;
		font-size: 0.32rem;
	}

	.information {
		width: 6.86rem;
		margin: 0 auto;
		margin-top: 0.36rem;
		position: relative;
		padding-bottom: 0.54rem;
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;
	}

	.mt {
		margin-top: 0.2rem;
	}

	.technological-main {
		font-size: 0.28rem;
		margin-top: 0.2rem;
	}

	.prl {
		padding: 0 0.3rem;
	}

	.technological-text {
		font-size: 0.22rem;
		text-align: center;
		margin-top: 0.28rem;
	}

	.technological-img {
		width: 0.88rem;
		height: 0.88rem;
		margin: 0 auto;
		margin-top: 0.24rem;
	}

	.technological-gray {
		font-size: 0.28rem;
		color: #BDBBBB;
		margin-top: 0.2rem;
	}

	.technological-txt {
		font-size: 0.3rem;
		color: #FF865C;
		margin-left: 0.1rem;
	}

	.technological-box {
		width: 0.12rem;
		height: 0.12rem;
		background-color: #FF865C;
		border-radius: 50%;
	}

	.flex {
		display: flex;
	}

	.border-top {
		border-top: 0.02rem solid #E5E3E3;
	}

	.bg-white {
		background-color: #FFFFFF;
	}

	.technological {
		padding: 0.5rem 0.4rem;
	}

	.page {
		background-color: #F6F6F6;
	}
</style>
